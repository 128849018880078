<template>
    <div id="app">
        <template v-if="loggedIn">
            <router-view v-if="!isDashboardDisplay" />
            <div
                v-if="isDashboardDisplay"
                class="homepage"
            >
                <div class="left-container">
                    <NavBar />
                </div>
                <div class="right-container">
                    <TopBar :current-route-name="currentRouteName" />
                    <div class="content-area">
                        <router-view />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <login />
        </template>
        <network-error />
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import TopBar from '@/components/TopBar.vue';
import Login from '@/views/Login.vue';
import NetworkError from '@/components/NetworkError.vue';
import { isValidRedirectUrl } from '@/utils/isValidRedirectUrl';
import 'vue2-datepicker/index.css';

@Component({
    components: {
        NavBar,
        Login,
        TopBar,
        NetworkError
    },
})
export default class App extends Vue {
    async mounted (): Promise<void> {
        await this.$store
            .dispatch('auth/getToken')
            .then((result) => {
                if (!result) {
                    if (this.$route.query?.signIn || this.$route.query?.signin) {
                        this.$router.push(this.$route.path);
                        this.login();
                    }
                }
            })
            .catch(() => {
                // TODO: how do we want to handle this?
            });
        if (this.$route.query?.signIn || this.$route.query?.signin) {
            this.$router.push(this.$route.path);
        }
    }

    async login (): Promise<void> {
        try {
            await this.$store.dispatch('auth/login');
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Login failed. Please try again.');
        }
        const url = this.$route.query.url as string;

        if (url && isValidRedirectUrl(url)) {
            window.location.href = url;
        } else {
            window.location.href = '';
        }

        // This code block might be redundant. We are not sure if it is used.
        this.$nextTick(() => {
            this.$store.dispatch('auth/getToken');
        });
    }

    get isDashboardDisplay (): boolean {
        const { name } = this.$route;
        if (name === 'Login') return false;
        if (name === 'Client') return false;
        return true;
    }

    get currentRouteName (): string {
        return this.$route.name;
    }

    get loggedIn (): boolean {
        return this.$store.getters['auth/loggedIn'];
    }
}
</script>
<style lang="scss">
@use "@/assets/scss/main.scss";
@use "@/assets/scss/abstracts/_base.scss";

#app {
    background-color: $SECONDARY;

    .homepage {
        display: flex;
        height: 100svh;
        padding: 5px;

        .left-container {
            width: 15%;
        }

        .right-container {
            display: flex;
            flex-direction: column;
            width: 85%;

            .content-area {
                flex-grow: 1;
                height: 100%;
                background-color: $bg-white;
                border-radius: 0 0 5px 5px;
                overflow-y: auto;
            }
        }
    }
}
</style>
